




import { Vue, Component } from 'vue-property-decorator';

@Component
export default class OAuthPopupCallback extends Vue {
  private mounted() {
    const { state } = this.$route.query;
    localStorage.setItem('oauth_callback_state', state as string);
    window.close();
  }
}
